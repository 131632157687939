import {
  CaseCompleteType,
  LeadListViewType,
  tanstackTableNames,
  useCaseCreationAndUpdateQueue,
  useCaseUpdateHandler,
  useDeleteCase,
  useGetCaseStatuses,
  useGetLeadsListView,
  useGetResourceSlideoverItems,
} from '@colosseum/data';
import {
  Button,
  CreateButton,
  DataTable,
  Form,
  SelectFormInput,
  Slideover,
  Typography,
} from '@colosseum/shared-ui';
import { ContactViewModelType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { z } from 'zod';
import ErrorPage from '../../error/ErrorPage';
import LeadSlideover from '../LeadSlideover/LeadSlideover';
import { columns } from './leads-page-table-columns';

const formSchema = z.object({
  caseStatusId: z.string().optional(),
});

export function LeadsPage() {
  const { caseId } = useParams();
  const navigate = useNavigate();

  const [createLeadLoading, setCreateLeadLoading] = useState(false);
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState<'open' | 'refer' | 'decline' | undefined>(
    undefined,
  );
  const [showConfetti, setShowConfetti] = useState(false);
  const [confirmStatusId, setConfirmStatusId] = useState<string | undefined>(undefined);

  const { createCaseAndLinks } = useCaseCreationAndUpdateQueue();

  const { data: leadsData, isLoading: isCasesLoading, isError: casesError } = useGetLeadsListView();
  const lead = leadsData?.data.find((c) => c.caseId === caseId) ?? (({} as CaseCompleteType) || {});

  const deleteCase = useDeleteCase();
  const { data: caseStatusesData } = useGetCaseStatuses();
  const { handleUpdateCase } = useCaseUpdateHandler(lead.caseId);
  const { notesCount, tasksCount, auditsCount } = useGetResourceSlideoverItems(caseId);

  const leadsListView = leadsData?.data.map((lead) => {
    return {
      ...lead,
      contact: lead.caseContactConnections[0]?.contact ?? [],
    } as LeadListViewType & { contact?: ContactViewModelType };
  });

  const caseStatuses = caseStatusesData?.data;
  const leads = leadsListView?.filter((c) => c.caseStatus?.category?.toLowerCase() === 'lead');

  const leadStatusId = caseStatuses?.find((cs) => cs.category === 'lead')?.caseStatusId;
  const caseStatusItems: { [key: string]: string } = caseStatuses
    ? caseStatuses
        ?.filter((cs) => {
          return cs.category === confirmStatus;
        })
        ?.reduce<{ [key: string]: string }>((acc, cs) => {
          if (cs.title) {
            acc[cs.title] = cs.caseStatusId;
          }
          return acc;
        }, {})
    : {};

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      caseStatusId: lead?.caseStatus?.caseStatusId,
    },
  });

  const handleRowClick = useCallback(
    (row: { original: CaseCompleteType }) => {
      const rowLeadId = row?.original?.caseId;

      if (!rowLeadId) {
        return;
      }

      if (rowLeadId === caseId) {
        setSlideoverOpen(true);
        return;
      }

      return navigate(`/leads/${rowLeadId}`, { replace: true });
    },
    [navigate],
  );

  const createLead = async () => {
    setCreateLeadLoading(true);

    if (!leadStatusId) {
      enqueueSnackbar('No Lead Category for Firm', {
        variant: 'error',
      });
      return;
    }

    await createCaseAndLinks({
      caseStatusId: leadStatusId,
    }).then((res) => {
      if (!res) {
        enqueueSnackbar('Error creating lead', {
          variant: 'error',
        });
        return;
      }
      setCreateLeadLoading(false);
      return navigate(`/leads/${res}`, { replace: true });
    });
  };

  useEffect(() => {
    setSlideoverOpen(!!caseId);
  }, [caseId]);

  const titleVar = _.capitalize(confirmStatus === 'open' ? 'Sign' : confirmStatus);

  if (casesError) {
    return (
      <ErrorPage
        code={500}
        errorTitle={'Something went wrong'}
        errorMessage={'We are working to get this resolved as soon as possible!'}
        backRoute="/home"
        backMessage="Back to Home"
      />
    );
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Leads</title>
      </Helmet>
      <Slideover
        title="Lead"
        description="Details about your lead."
        open={slideoverOpen}
        setOpen={setSlideoverOpen}
        tabHeaders={[
          {
            name: 'Details',
            value: 'details',
            showBottomButtons: true,
          },
          {
            name: 'Notes',
            value: 'notes',
            showBottomButtons: true,
            count: notesCount.toString(),
          },
          {
            name: 'Tasks',
            value: 'tasks',
            showBottomButtons: true,
            count: tasksCount.toString(),
          },
          {
            name: 'Feed',
            value: 'history',
            showBottomButtons: true,
            count: auditsCount.toString(),
          },
        ]}
        deleteFunction={() => {
          if (!caseId) {
            return;
          }

          deleteCase.mutate(caseId);
        }}
        submitButton={
          <div className="flex items-center gap-x-2.5 py-3">
            <CreateButton
              title="Sign"
              onClick={() => {
                setConfirmStatus('open');
                setConfirmOpen(true);
              }}
              className="sm:w-16"
            />
            <CreateButton
              title="Refer"
              onClick={() => {
                setConfirmStatus('refer');
                setConfirmOpen(true);
              }}
              className="sm:w-16"
            />
            <CreateButton
              title="Decline"
              onClick={() => {
                setConfirmStatus('decline');
                setConfirmOpen(true);
              }}
              className="sm:w-16"
            />
          </div>
        }
        onClose={() => {
          navigate('/leads');
        }}
      >
        <LeadSlideover casesData={leadsData?.data} isCasesLoading={isCasesLoading} />
      </Slideover>
      <Slideover
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title={`Confirm Lead Conversion: ${titleVar}`}
        description={`Confirm that you would like to convert this lead to the "${titleVar}" path. Please fill out the necessary details below and click confirm to continue. This action cannot be undone.`}
        bottomButtons={
          <div className="flex items-center justify-between px-4 py-4 border-t border-t-gray-200">
            <Button
              variant="outlinePrimary"
              className="inline-flex justify-center px-6 py-2 text-sm font-medium border rounded-md shadow-sm text-sky-blue border-sky-blue hover:text-atlantic-blue hover:border-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atlantic-blue"
              onClick={() => {
                setConfirmOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="px-4 py-2 text-white rounded-md bg-atlantic-blue"
              onClick={() => {
                if (!confirmStatusId) {
                  enqueueSnackbar('No Status Selected', {
                    variant: 'error',
                  });
                  return;
                }

                if (confirmStatus === 'open' && confirmStatusId) {
                  setShowConfetti(true);
                  setTimeout(() => {
                    setShowConfetti(false);
                  }, 2000);
                }
                handleUpdateCase('caseStatusId', confirmStatusId);
                setConfirmOpen(false);
                setSlideoverOpen(false);
              }}
            >
              {showConfetti && <ConfettiExplosion zIndex={9999} />}
              Confirm
            </Button>
          </div>
        }
      >
        <div>
          <Form {...form}>
            <form>
              <SelectFormInput
                listItems={caseStatusItems}
                name="caseStatusId"
                title="Case Status"
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;

                  setConfirmStatusId(target.value);
                }}
                listItemsIsObject
              />
            </form>
          </Form>
        </div>
      </Slideover>
      <div className="relative mt-5 bg-white border shadow-sm rounded-xl" data-cy="leads-list-view">
        <div className="flex flex-wrap items-end justify-between p-4">
          <Typography tag="h1" variant="pageHeading">
            Leads
          </Typography>
          <div className="flex flex-wrap items-end space-y-5 ">
            <CreateButton title="Create Lead" onClick={createLead} loading={createLeadLoading} />
          </div>
        </div>
        <div className="px-4 pb-4 mt-5">
          <DataTable
            data={leads ?? []}
            columns={columns}
            handleRowClick={handleRowClick}
            initialSort={{
              id: 'Open Date',
              desc: true,
            }}
            showSearchBar
            initialVisibility={{
              'Days in Status': false,
              'Lead Status Goal': false,
            }}
            disableAutoResetPageIndex
            persistentVisibility={{
              // These are always hidden, even if the user has column preferences in local storage
              Search: false,
            }}
            isLoading={isCasesLoading}
            isError={casesError}
            tableName={tanstackTableNames.leads}
            showCSVDownload
          />
        </div>
      </div>
    </>
  );
}

export default LeadsPage;
